import React, {useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import {useRouter} from 'next/router'

import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'

import Text from '@component/Text/Text'
import WizNavTabs, {Tab} from '@component/tabs/WizNavTabs'
import {
    getLinkUrl,
    URL_ACCOUNT_API,
    URL_ACCOUNT_INVOICE,
    URL_ACCOUNT_NOTIFICATION,
    URL_ACCOUNT_POINT,
    URL_ACCOUNT_PRO,
    URL_ACCOUNT_REFERRAL,
    URL_ACCOUNT_SETTING,
    URL_ACCOUNT_SUBSCRIBER,
    URL_ACCOUNT_WALLET,
} from '@constant/url'

interface IProps {
    className?: string
}

const AccountNavigationTabs: React.FC<IProps> = ({className}) => {
    const {t} = useTranslation()
    const router = useRouter()

    const {data: me} = useQueryFetchMe(undefined, false)

    const inActiveKeyLength = useMemo(() => me?.keys?.filter(key => key?.is_active !== true)?.length || 0, [me?.keys])

    const TABS: Tab[] = useMemo(
        () => [
            {
                id: [`/account/setting`],
                label: t('account.menu.setting'),
                link: getLinkUrl(URL_ACCOUNT_SETTING),
            },
            {
                id: ['/account/api', '/account/api/create', '/account/api/register'],
                label: t('account.menu.api'),
                link: getLinkUrl(URL_ACCOUNT_API),
                renderRightArea: inActiveKeyLength > 0 && (
                    <div className={'flex items-center justify-center ml-[5px]'}>
                        <Text className={'text-ti4 bg-red dark:bg-dark_red text-white rounded-full px-[5px]'}>
                            {inActiveKeyLength}
                        </Text>
                    </div>
                ),
            },
            {
                id: [
                    '/account/wallet',
                    '/account/wallet/history',
                    '/account/wallet/withdraw',
                    '/account/wallet/withdraw/complete',
                    '/account/wallet/withdraw/email/sent',
                    '/account/wallet/2fa/setup/email',
                    '/account/wallet/2fa/setup/register',
                    '/account/wallet/2fa/setup/complete',
                ],
                label: t('account.menu.wallet'),
                link: getLinkUrl(URL_ACCOUNT_WALLET),
            },
            {
                id: ['/account/invoice'],
                label: t('account.menu.invoice'),
                link: getLinkUrl(URL_ACCOUNT_INVOICE),
                renderRightArea: me?.pending_invoices > 0 && (
                    <div className={'flex items-center justify-center ml-[5px]'}>
                        <Text className={'text-ti4 bg-red dark:bg-dark_red text-white rounded-full px-[5px]'}>
                            {me?.pending_invoices}
                        </Text>
                    </div>
                ),
            },
            {
                id: ['/account/subscriber'],
                label: t('account.menu.subscriber'),
                link: getLinkUrl(URL_ACCOUNT_SUBSCRIBER),
            },
            {
                id: ['/account/notification'],
                label: t('account.menu.notifications'),
                link: getLinkUrl(URL_ACCOUNT_NOTIFICATION),
            },
            {
                id: ['/account/referral', '/account/referral/history', '/account/referral/rule'],
                label: t('account.menu.referral'),
                link: getLinkUrl(URL_ACCOUNT_REFERRAL),
            },
            {
                id: ['/account/pro'],
                label: t('account.menu.pro'),
                link: getLinkUrl(URL_ACCOUNT_PRO),
            },
            {
                id: ['/account/point'],
                label: t('account.menu.point'),
                link: getLinkUrl(URL_ACCOUNT_POINT),
            },
        ],
        [t, inActiveKeyLength, me?.pending_invoices],
    )
    return (
        <WizNavTabs
            type={'links'}
            tabs={TABS}
            selectedTab={router?.pathname}
            buttonClassName={'lg:mr-[5px] md:mr-[10px] lg:px-[16px] md:px-[20px]'}
        />
    )
}
export default React.memo(AccountNavigationTabs)
