import React, {useCallback, useMemo, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import Text from '@component/Text/Text'
import UserThumbnail from '@component/images/UserThumbnail'
import WizDropdown from '../../../components/dropdowns/WizDropdown'
import IconEdit from '@svg/common/ic_edit.svg'
import {apiUploadImage} from '@api/core/image/uploadImage'
import {apiEditThumbnail} from '@api/user/account/editThumbnail'
import useSnackbar from '@hook/useSnackbar'
import useQueryFetchMe from '@hook/query/account/useQueryFetchMe'
import ProTag from '@component/tags/ProTag'
import {ILoginInfoData} from '@store/LoginStore'
import WizIcon from '@component/images/WizIcon'
import {useRouter} from 'next/router'
import AccountNavigationTabs from '@feature/account/layouts/AccountNavigationTabs'
import SkeletonBlock from '@component/skeleton/SkeletonBlock'
import {addErrorLog, ERROR_LOG} from '@constant/log'

interface IProps {
    children?: React.ReactNode
    layoutClassName?: string
}

const AccountLayout = ({
    layoutClassName = 'layout-desktop_1142 default_page_padding sm:pt-[15px] sm:px-[20px]',
    children,
}: IProps) => {
    const {t} = useTranslation()
    const {showSnackbar, showErrorSnackbar} = useSnackbar()
    const router = useRouter()

    const {data: me, refetch: refreshMe, isLoading} = useQueryFetchMe(undefined, false)

    const userLocalName = useMemo(() => {
        return me?.nicknames?.find(item => item.language === router?.locale)?.nickname ?? me?.name
    }, [me, router?.locale])
    const imageFileRef = useRef<any>()

    const changeImage = async e => {
        const file = e?.target?.files[0]

        if (file) {
            const result = await apiUploadImage(file)
            if (result.success) {
                const {data, error} = await apiEditThumbnail(result.image_url)
                if (data?.success) {
                    showSnackbar(t('account.thumbnail.changeImage.success'))
                    const prevAccount: ILoginInfoData = JSON.parse(localStorage.getItem('user_accounts'))?.find(
                        item => item?.name === me?.name,
                    )

                    const accountsFilterArr: ILoginInfoData[] = JSON.parse(
                        localStorage.getItem('user_accounts'),
                    )?.filter(item => item?.name !== me?.name)

                    localStorage.setItem(
                        'user_accounts',
                        JSON.stringify([
                            ...accountsFilterArr,
                            {
                                ...prevAccount,
                                thumbnail: result?.image_url,
                            },
                        ]),
                    )
                    refreshMe()
                    return
                } else {
                    showErrorSnackbar(error)
                    addErrorLog(ERROR_LOG.FAIL_CHANGE_USER_THUMBNAIL)
                }
            }
        }

        e.target.value = ''
        showSnackbar(t('account.thumbnail.changeImage.fail'))
        return
    }

    const resetDefaultImage = useCallback(async () => {
        const {data, error} = await apiEditThumbnail('')
        if (data) {
            showSnackbar(t('account.thumbnail.changeImage.success'))
            refreshMe()
        } else {
            showErrorSnackbar(error)
        }
    }, [showSnackbar, showErrorSnackbar, refreshMe, t])

    const onPickImage = () => {
        imageFileRef && imageFileRef.current.click()
    }
    const imageMenuOptionList = useMemo(
        () => [
            {title: t('account.thumbnail.menu.pickImage'), onClick: onPickImage},
            {title: t('account.thumbnail.menu.setDefault'), onClick: resetDefaultImage},
        ],
        [t, resetDefaultImage],
    )

    return (
        <div className={`${layoutClassName}`} data-cy={'account_layout'}>
            <div className={'flex flex-col items-center mt-8 md:hidden'}>
                <WizDropdown
                    containerClassName={'rounded-full'}
                    downViewAlign={'left'}
                    optionList={imageMenuOptionList}
                    renderView={
                        <div className={'relative '}>
                            {isLoading ? (
                                <SkeletonBlock
                                    className={'w-[90px] h-[90px] rounded-full'}
                                    sizeClassName={'h-full rounded-full'}
                                />
                            ) : (
                                <UserThumbnail src={me?.thumbnail} size={90} isPro={me?.is_pro} />
                            )}
                            <div className="absolute z-10 right-0 bottom-0">
                                <WizIcon
                                    className={
                                        'bg-white dark:bg-bg_dark_background border border-gray07 dark:border-dark_gray07 p-[2px] hover:bg-gray06 dark:hover:bg-dark_gray06'
                                    }>
                                    <IconEdit className={'w-[16px] fill-gray02 dark:fill-dark_gray02'} />
                                </WizIcon>
                            </div>
                        </div>
                    }
                />
                <input
                    type={'file'}
                    style={{display: 'none'}}
                    accept=".jpg, .jpeg, .png, .svg, .webp, .gif"
                    onChange={e => {
                        changeImage(e)
                    }}
                    ref={imageFileRef}
                />
                <div className={'flex gap-x-[10px] items-center mt-[5px]'}>
                    {isLoading ? (
                        <SkeletonBlock className={'w-[200px] h-[36px] mx-auto'} sizeClassName={'h-[24px]'} />
                    ) : (
                        <Text className={'text-h2 text-center break-all text-gray01 dark:text-dark_gray01'}>
                            {userLocalName || ''}
                        </Text>
                    )}
                    {me?.is_pro && <ProTag size={'small'} />}
                </div>
            </div>
            <div className={'flex justify-center mt-[20px] md:hidden'}>
                <AccountNavigationTabs />
            </div>
            {children}
        </div>
    )
}

export default AccountLayout
